import { Swiper, SwiperSlide } from 'swiper/react'; 
import 'swiper/css'; // Core Swiper styles
import 'swiper/css/autoplay'; // Swiper Autoplay styles
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./HomeSlider.css"
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import userIcon from '../../assets/icon/user.png';
import cmsIcon from '../../assets/icon/cms.png';
import scalIcon from '../../assets/icon/scal.jpg';
import performIcon from '../../assets/icon/perform.png';
import seoIcon from '../../assets/icon/seo.png';
import securityIcon from '../../assets/icon/security.jpg';
import ecomersIcon from '../../assets/icon/ecomers.jpg';
import mobileIcon from '../../assets/icon/mobile.jpg';
import socialIcon from '../../assets/icon/social.jpg';
import contactformIcon from '../../assets/icon/contactform.jpg';
import anylisisIcon from '../../assets/icon/anylisis.jpg';
import brandIcon from '../../assets/icon/brand.jpg';
import cdnIcon from '../../assets/icon/cdn.jpg';
import accessIcon from '../../assets/icon/access.jpg';
import backupIcon from '../../assets/icon/backup.jpg';
import colabrationIcon from '../../assets/icon/colabration.jpg';
import ligalIcon from '../../assets/icon/ligal.jpg';
import lastIcon from '../../assets/icon/last.jpg';
import webdesineIcon from '../../assets/icon/webdesine.jpg';
import dominIcon from '../../assets/icon/domin.jpg';
import hoistingIcon from '../../assets/icon/hoisting.jpg';
import responsiveIcon from '../../assets/icon/responsive.jpg';
import userappIcon from '../../assets/icon/userapp.jpg';
import apiIcon from '../../assets/icon/api.jpg';
import { Link } from 'react-router-dom';

const functionalities = [
    {
      use: userIcon,
      title: "User-Friendly Interface",
    //   description: "Intuitive navigation and responsive design.",
    },
    {
      use: cmsIcon,
      title: "Content Management System (CMS)",
    //   description: "Easy-to-use CMS for content management.",
    },
    {
      use: scalIcon,
      title: "Scalability",
    //   description: "Ability to scale the website as the business grows.",
    },
    {
      use: performIcon,
      title: "Performance Optimization",
    //   description: "Fast loading times and optimization of assets.",
    },
    {
      use: seoIcon,
      title: "Search Engine Optimization (SEO)",
    //   description: "SEO-friendly structure and metadata.",
    },
    {
      use: securityIcon,
      title: "Security",
    //   description: "Implementation of security measures and SSL certificates.",
    },
    {
      use: ecomersIcon,
      title: "E-Commerce Functionality",
    //   description: "Shopping cart system, payment gateways, and inventory management.",
    },
    {
      use: mobileIcon,
      title: "Mobile Responsiveness",
    //   description: "Mobile-friendly design for seamless user experience.",
    },
    {
      use: socialIcon,
      title: "Social Media Integration",
    //   description: "Integration with social media platforms.",
    },
    {
      use: contactformIcon,
      title: "Contact Forms and Communication",
    //   description: "Easy-to-use contact forms and integration with email services.",
    },
    {
      use: anylisisIcon,
      title: "Analytics and Reporting",
    //   description: "Integration with analytics tools and customized reports.",
    },
    {
      use: brandIcon,
      title: "Customization and Branding",
    //   description: "Flexible design options and customizable themes.",
    },
    {
      use: cdnIcon,
      title: "Content Delivery Network (CDN)",
    //   description: "Utilization of CDN for faster content delivery.",
    },
    {
      use: accessIcon,
      title: "Accessibility",
    //   description: "Compliance with web accessibility standards (WCAG).",
    },
    {
      use: backupIcon,
      title: "Backup and Recovery",
    //   description: "Regular automated backups and easy recovery options.",
    },
    {
      use: colabrationIcon,
      title: "Collaboration and Communication",
    //   description: "Collaboration tools and project management systems.",
    },
    {
      use: ligalIcon,
      title: "Legal Compliance",
    //   description: "Compliance with legal requirements, including GDPR.",
    },
    {
      use: lastIcon,
      title: "Support and Maintenance",
    //   description: "Ongoing support and maintenance services.",
    },
    {
      use: webdesineIcon,
      title: "Website Design",
    //   description: "Professional website design services tailored to your business needs.",
    },
    {
      use: dominIcon,
      title: "Domain Registration",
    //   description: "Secure and register your unique domain name for your online presence.",
    },
    {
      use: hoistingIcon,
      title: "Web Hosting",
    //   description: "Reliable web hosting services to ensure your website is accessible to users.",
    },
    {
      use: responsiveIcon,
      title: "Mobile Responsiveness",
    //   description: "Optimized design to ensure seamless user experience on various devices, including smartphones and tablets.",
    },
    {
      use: userappIcon,
      title: "User-Friendly App Design",
    //   description: "Create an intuitive and user-friendly app interface that enhances the overall user experience, making it easy for users to navigate and interact with the application.",
    },
    {
      use: apiIcon,
      title: "API Integration",
    //   description: "Incorporate seamless API integrations to enhance app functionality, connect with third-party services, and provide users with dynamic and up-to-date content.",
    },
  ];
  

const HomeSlider = () => {
  return (
    <div className="home-slider">
        <div className="containeraa">
    <h1>Services</h1>
    <Link to="/services" className="btn1">See More</Link>
</div>

    <Swiper
      style={{ height: '350px' }}
  
      centeredSlides={true}
      spaceBetween={30}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination, Navigation]}
      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
      }}
     className="mySwiper"
    >
      {functionalities.map((func, index) => (
        <SwiperSlide key={index}>
         
          <div className="functionality-card" key={index}>
            <img className="functionality-image" src={func.use} alt={func.title} />
            <div className="functionality-content">
              <h3 className='h333'>{func.title}</h3>
              {/* <p>{func.description}</p> */}
            </div>
          </div>
       
        </SwiperSlide>
      ))}
    </Swiper>
    </div>
  );
};

export default HomeSlider;
