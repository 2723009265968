import Contact from './Contact/Contact'
import Projects from './Project/Projects'
import Service from './Coursefront/Service'
import HomeSlider from './HomeSlider/HomeSlider'
import web from "../assets/Home/web.jpg"
import frontend from "../assets/Home/frontend.jpg"
import mern from "../assets/Home/mern.png"
import { Helmet } from 'react-helmet'



const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Raicoder</title>
        {/* <meta name="description" content=" Welcome to Raicoder your premier coding skills provider dedicated to empowering individuals through exceptional education in web development. We focus on MERN, consisting of MongoDB, Express.js, React, and Node.js, in our well-rounded curriculum designed to equip students with the skills required to succeed in the world of tech." /> */}
      </Helmet>
       
        <Service/>

<div className='flexbox'>
  <div className='fristone'>
    <h1 className='heading1'>Welcome to CourseFront</h1>
    <h4 className='h22'>Web development for any beginner is fun with proper guidance. Here in raicoder.com, we start with the basics to lay a sound foundation to web development.
</h4>
    <h4 className='h22'>First you learn HTML or Hypertext Markup Language, what basically structures the content, and then comes CSS, or Cascading Style Sheets, which styles the content and makes your websites look good. It's all about understanding the basis of HTML and CSS.</h4>

<h4 className='h22'>Having mastered HTML and CSS, the student then moves to JavaScript, one of the powerful programming languages that add interactivity to a website: dynamic content, animation, and so on.</h4>

<h4 className='h22'>At Raicoder, the learning pattern is step-by-step. Here, extremely small projects are taken initially. Then subsequently, the projects become increasingly complex and vast. Beginners can also learn tools like Git for version control and VS Code for efficient coding. The students at Raicoder get hands-on practice in learning enough practical skills to build a website from scratch. This builds their foundation well for more complex subjects like frameworks, backend development, and full-stack programming.</h4></div>
  <div className='fristtwo'><img className='imagee' src={web} alt=""/></div>

</div>


<div className='flexbox'>
<div className='fristtwo'><img className='imagee' src={frontend} alt=""/></div>
  <div className='fristone'>
    <h1 className='heading1'>Welcome to Frontend</h1>
    <h4 className='h22'>HTML & CSS
    This is where the development of a website really starts. The student will learn how to structure and style content using HTML and CSS in order to create the skeleton of any web site.</h4>
    <h4 className='h22'>Build Projects
    Learn by making: Once one understands how to create the back-bone of a web site through HTML and CSS, start building real-world projects to cement what has been learned and gain hands on experience.</h4>
    <h4 className='h22'>Git & GitHub
    Version control Version control is something every developer needs to know. Students learn Git so they can track their code changes and use GitHub for online collaboration and projects.</h4>

<h4 className='h22'>JavaScript Now we'll add interactivity to the Web. Students learn how to dynamically manipulate web pages and deal with user input so sites become more engaging.</h4>

<h4 className='h22'>Build Projects
Once they learn the basics of JavaScript, students will learn how to make more sophisticated projects that apply interactivity and dynamic aspects to their web pages.
</h4>
<h4 className='h22'>CSS Frameworks
The use of speed and consistency in design will be enhanced when students are introduced to commonly used CSS frameworks such as Bootstrap or Tailwind CSS, which will enable them to make responsive, professional-looking pages much more quickly</h4>


<h4 className='h22'>Basics of the Backend
Even though frontend development is all about client-side development, understanding the basics of backend development, like databases and servers, provides a whole picture of how web applications function</h4>
</div>
  

</div>



<div className='flexbox'>
  <div className='fristone'>
    <h1 className='heading1'>Welcome to Mern</h1>
    <h4 className='h22'>Node.js & Express
    Once you are pretty comfortable with the frontend, move on to the backend using Node.js. Learn how to build your server-side using Express.js, routing, making requests to APIs, and interacting with your database-where you start to connect all the dots in full-stack development.
</h4>
    <h4 className='h22'>MongoDB
Explore databases with MongoDB, a NoSQL database. It lets you store and manage your data in flexible ways. Learn how to integrate it into your Node.js backend for a full-stack experience.
</h4>

<h4 className='h22'>React

React is the frontend framework of the MERN stack. React adds value to your applications by allowing you to build powerful, interactive user interfaces efficiently.
With all of these components of the MERN stack in hand, it is time to really start building some full-stack applications. It is time to put MongoDB, Express, React, and Node.js together to build dynamic web applications from scratch.</h4>

<h4 className='h22'>Advanced Concepts & Deployment
Through advanced concepts like JWT authentication, session management, and deployment strategies, you will learn how to take your applications live on Heroku or DigitalOcean and apply for jobs.
At this point, you should have developed a portfolio of full-stack projects that speak for themselves as you apply to jobs.</h4></div>
  <div className='fristtwo'><img className='imagee' src={mern} alt=""/></div>

</div>


        <HomeSlider/>
    <Projects/>
    <Contact/>
    </div>
  )
}

export default Home
