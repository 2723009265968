import { MailIcon, MapPinCheck, PhoneCall } from "lucide-react"
import "./Footer.css"
import insta from "../../assets/instagram.svg"
import whatsapp from "../../assets/whatsapp.svg"
import logo from "../../assets/About/icon.png"
import { Link } from "react-router-dom"



const Footer = () => {
  return (
    <div className="footer">
    
  <div className="footer-left">
    <img   height={"70px"}  src={logo} alt="Logo" />

    <h3 itemProp="name" className="p-name">
      Rai Coder
    </h3>
    <p className="p-job-title" itemProp="jobTitle">
    Skill Provider & Website Development Company
    </p>
    <nav aria-label="Footer Navigation">
      <p className="footer-links">
        <Link to="/">
          Home
          </Link>
      <Link to="/courses">
         Course
         </Link>
    
        <Link to="/projects">
          Projects
          </Link>
       <Link too="/services">
          Services
          </Link>
      </p>
    </nav>
    <p className="footer-name">© 2024 RaiCoder</p>
  </div>
  <div className="footer-center">
    <div
    
    >
      <p className="iconn">{<MapPinCheck/>}</p>
      <p>
        <span itemProp="streetAddress" className="p-street-address">
          121A Dwarka 
        </span>
        <span itemProp="addressLocality" className="p-locality">
        Near Dwarka mor metro station
        </span>
        <span itemProp="addressRegion" className="p-region">
          Delhi 
        </span>
        <span itemProp="postalCode" className="p-postal-code">
        110059
        </span>
      </p>
    </div>
    <div>
    <p className="iconn">{<PhoneCall/>}</p>

      <p itemProp="telephone" className="p-tel">
        +91 8287825720
      </p>
    </div>
    <div>
    <p className="iconn">{<MailIcon/>}</p>

      <p>
        <a href="mailto:myname@mail.com" itemProp="email" className="u-email">
          shwet@raicoder.com
        </a>
      </p>
    </div>
  </div>
  <div className="footer-right">
    <p className="footer-about">
      <span>About Me</span>
      Raicoder specializes in skill development and website creation. We're dedicated to empowering individuals and businesses for online success.
    </p>
    <div className="footer-socials">
  {/* WhatsApp link */}
  <a href="https://wa.me/8287825720" target="_blank" rel="noopener noreferrer">
    <img height={"40px"} src={whatsapp} alt="WhatsApp" />
  </a>

  {/* Instagram link */}
  <a href="https://www.instagram.com/rai_coder/" target="_blank" rel="noopener noreferrer">
    <img height={"40px"} src={insta} alt="Instagram" />
  </a>
</div>
</div>
</div>
  )
}

export default Footer
