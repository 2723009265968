import React from 'react'
import "./About.css"
import imgg from "../../assets/About/abb.jpg"
import { Helmet } from 'react-helmet'

const About = () => {
  return (
    
    <> <div className='flexbox1'>
      <Helmet>
        <title>About Raicoder</title>
        <meta name="description" content=" Welcome to Raicoder your premier coding skills provider dedicated to empowering individuals through exceptional education in web development. We focus on MERN, consisting of MongoDB, Express.js, React, and Node.js, in our well-rounded curriculum designed to equip students with the skills required to succeed in the world of tech." />
      </Helmet>
     
    {/*==================== ABOUT ====================*/}
<div className='fristtwo1'>
      <div className="about__container container">
       
    
        <div className="about__perfil">
          <div className="about__image">
            <img  className="iimg"
              src={imgg} alt='ss'/>
            <div className="about__shadow" />
            <div className="geometric-box" />
            <div className="about__box" />
          </div>
        </div>
        
      </div>
      </div>
      
   <div className='fristone1'>
   <h2 className="section__title-1">
          <span>About RaiCoder.</span>
        </h2>
      <div className="about__inf0">
          <p className="about__description">
          <b>Welcome to Raicoder</b>, your premier coding skills provider dedicated to empowering individuals through exceptional education in web development. We focus on MERN, consisting of MongoDB, Express.js, React, and Node.js, in our well-rounded curriculum designed to equip students with the skills required to succeed in the world of tech.
          </p>
          
          <p className="about__description">
          <b>In Raicoder</b>, you are sure to get a guaranteed learning experience. The curriculum is so poised that it assures your complete range of must-haves and leaves no room for any doubt. You can be rest assured to receive the best that can be offered. Be it fresher who is all ready to be coded or it is an experience developer trying to advance his skills; our programs for all.
          </p>
          
         
        </div>
        </div>
    </div>
    
    
    
    
   
    <div className='flexbox1'>
     
    {/*==================== ABOUT ====================*/}
    <div className='fristone1'>

<div className="about__inf0">
    <p className="about__description">
    <b>Our faculty:</b> A group of seasoned, expert instructors who have had decades of experience working in the industry. They keep delivering to you insights and learning from the actual vanguard of the practice rather than textbook-based knowledge.
    </p>
    
    <p className="about__description">
    Interactive <b>Doubt Sessions</b>: We believe that concepts should be understood properly. That's why we are offering devoted doubt sessions that will enable the learners to clarify their queries and consolidate their understanding of complex topics.

    </p>

    <p className="about__description">
    Demonstrated Track Record: From there, we started operating from <b>Tech Akiras</b> three years ago and transformed into Raicoder to help our growing community better. We are more than 40 team members, continually expanding to improve our offerings and support our students properly.

    </p>

    <p className="about__description">
    Join <b>Raicoder</b> and step closer to learning web development. Join our programs if you want to become a successful full-stack developer; or, maybe you simply need to know coding well. The programs are structured for guiding you step by step.

    </p>
    
  
  </div>
  </div>



<div className='fristtwo1'>
      <div className="about__container container">
       
    
        <div className="about__perfil">
          <div className="about__image">
            <img  className="iimg"
              src="https://lh3.googleusercontent.com/p/AF1QipMM43X0WxyXyaBBvrnGESk0Q6pdglUJhKNwiQaF=s1360-w1360-h1020" alt='ss'/>
            <div className="about__shadow" />
            <div className="geometric-box" />
            <div className="about__box" />
          </div>
        </div>
        
      </div>
      </div>
      
 
    </div>

     
    </>
  )
}

export default About
