import { useState } from 'react'
import "./Contact.css"
import '@google/model-viewer';
import insta from "../../assets/instagram.svg"
import whatsapp from "../../assets/whatsapp.svg"
import leptop from "../../assets/laptop.glb"
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';



const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };



  const handleSubmit = async () => {
    // Simple form validation
    const { name, email, message, phone } = formData;
  
    if (!name || !email || !message || !phone) {
      toast.error('Please fill in all the fields', {
        position: 'top-right',
      });
      return; // Exit the function if validation fails
    }
  
    // If validation passes, proceed with form submission
    axios.post('https://script.google.com/macros/s/AKfycbxMgcbKIePh3XRjT_onvcIWtKpBMSsZc4eDfjSGG6ykzj-NqKEIh6JxYdNXL3tX15S2/exec', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(response => {
        console.log('Form submitted successfully:', response.data);
        toast.success('Form submitted successfully!', {
          position: 'top-right',
        });
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        toast.error('Error submitting form. Please try again.', {
          position: 'top-right',
        });
      });
  };
  
  
  


  const handleReset = () => {
    setFormData({
      name: '',
      email: '',
      message: '',
      phone: '',
    });
  };
  return (
    <div className='mainn'>
     
      <div className="form-container-main">

        <div className="form-container">
          <div className="form-image">
            <h1 className="montserrat">Get in touch</h1>
            <model-viewer

              alt="laptop"
              src={leptop}
              shadow-intensity="1"
              camera-controls
              touch-action="pan-y"
              exposure="1.5"
              disable-zoom
              disable-tap
              camera-orbit="-45deg 60deg 9m"
              autoplay
              style={{ height: '300px', width: '100%' }} // Set the height here
            />
            <div className='contacticon'>
              <a href="https://wa.me/8287825720" target="_blank" rel="noopener noreferrer">
                <img height={"40px"} src={whatsapp} alt="" />
              </a>

              <a href="https://www.instagram.com/rai_coder/" target="_blank" rel="noopener noreferrer">
                <img height={"40px"} src={insta} alt="" />
              </a>

            </div>
          </div>
          <div className="form">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              autoFocus
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
            />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone Number"
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              cols="30"
              rows="10"
              placeholder="Say Hello"
            />
            <div className="button-container">
              <div className="send-button" onClick={handleSubmit}>Send</div>
              <div className="reset-button-container">
                <div className="reset-button" onClick={handleReset}>Reset</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
