// // src/Navbar.js
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import './Navbar.css'; // Ensure this path is correct based on your project structure

// const Navbar = () => {
//   const [isActive, setIsActive] = useState(false);

//   const toggleMenu = () => {
//     setIsActive(!isActive);
//     localStorage.setItem("userData", JSON.stringify("userData"));
//   };

//   const handleLinkClick = () => {
//     setIsActive(false); // Close the menu when a link is clicked
//   };

//   return (
//     <div className='main'>
//       <div className={`containera ${isActive ? 'active' : ''}`}>
//         <div className="navbar">
//           <div className="menu">
//             <h3 className="logo">
//               Rai<span>Coder</span>
//             </h3>
//             <div className="hamburger-menu" onClick={toggleMenu}>
//               <div className="bar"></div>
//             </div>
//           </div>
//         </div>

//         <div className="main-container">
//           <div className="main">
//             <header>
//               <div className="overlay">
//                 <div className="inner">
//                   <h2 className="white">Full Stack Developer</h2>
//                   <p>Unlock your potential and elevate your coding skills with our engaging courses designed for students of all levels</p>
//                   <Link to={"/"} onClick={handleLinkClick}>
//                     <button className="btn">READ MORE</button>
//                   </Link>
//                 </div>
//               </div>
//             </header>
//           </div>

//           <div className="shadow one"></div>
//           <div className="shadow two"></div>
//         </div>

//         <div className="links">
//           <ul>
//             <li>
//               <Link to="/" style={{ '--i': '0.05s' }} onClick={handleLinkClick}>Home</Link>
//             </li>
//             <li>
//               <Link to="/courses" style={{ '--i': '0.1s' }} onClick={handleLinkClick}>Courses</Link>
//             </li>
//             <li>
//               <Link to="/services" style={{ '--i': '0.15s' }} onClick={handleLinkClick}>Services</Link>
//             </li>
//             <li>
//               <Link to="/projects" style={{ '--i': '0.2s' }} onClick={handleLinkClick}>Projects</Link>
//             </li>
//             <li>
//               <Link to="/about" style={{ '--i': '0.25s' }} onClick={handleLinkClick}>About</Link>
//             </li>
//             <li>
//               <Link to="/contact" style={{ '--i': '0.3s' }} onClick={handleLinkClick}>Contact</Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;



// src/Navbar.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css'; // Ensure this path is correct based on your project structure

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation(); // Get the current route

  const toggleMenu = () => {
    setIsActive(!isActive);
    localStorage.setItem("userData", JSON.stringify("userData"));
  };

  const handleLinkClick = () => {
    setIsActive(false); // Close the menu when a link is clicked
  };

  // Configuration for different pages
  const pageConfig = {
    '/': {
      backgroundImage: 'url(/path/to/home.jpg)', // Change to your actual path
      title: 'Welcome to RaiCoder',
      description: 'Unlock your potential and elevate your coding skills with our engaging courses designed for students of all levels....',
      buttonText: 'GET STARTED',
      description1: 'Website Development & Digital Marketing Services',

      link: '/courses',
    },
    '/courses': {
      backgroundImage: 'url(/path/to/courses.jpg)', // Change to your actual path
      title: 'Explore Our Courses',
      description: 'Find the course that suits you best.',
      description1: 'Website Development & Digital Marketing Services',
      buttonText: 'VIEW COURSES',
      link: '/courses',
    },
    '/services': {
      backgroundImage: 'url(/path/to/services.jpg)', // Change to your actual path
      description1: 'Website Development & Digital Marketing Services',
      title: 'Our Services',
      description: 'Discover what we can offer you.',
      buttonText: 'LEARN MORE',
      link: '/services',
    },
    '/projects': {
      backgroundImage: 'url(/path/to/projects.jpg)', // Change to your actual path
      description1: 'Website Development & Digital Marketing Services',
      title: 'Our Projects',
      description: 'Check out the projects we’ve completed.',
      buttonText: 'SEE PROJECTS',
      link: '/projects',
    },
    '/about': {
      backgroundImage: 'url(/path/to/about.jpg)', // Change to your actual path
      description1: 'Website Development & Digital Marketing Services',
      title: 'About Us',
      description: 'Learn more about our mission and values.',
      buttonText: 'READ MORE',
      link: '/about',
    },
    '/contact': {
      backgroundImage: 'url(/path/to/contact.jpg)', // Change to your actual path
      description1: 'Website Development & Digital Marketing Services',
      title: 'Get in Touch',
      description: 'We’d love to hear from you!',
      buttonText: 'CONTACT US',
      link: '/contact',
    },
  };

  const { backgroundImage, title, description,description1, buttonText, link } = pageConfig[location.pathname] || pageConfig['/'];

  return (
    <div className='main'>
      <div className={`containera ${isActive ? 'active' : ''}`}>
        <div className="navbar">
          <div className="menu">
            <h3 className="logo">
              Rai<span>Coder</span>
            </h3>
            <div className="hamburger-menu" onClick={toggleMenu}>
              <div className="bar"></div>
            </div>
          </div>
        </div>

        <div className="main-container" >
          <div className="main">
            <header >
              <div className="overlay">
                <div className="inner">
                  <h2 className="white">{title}</h2>
                  <p>{description}</p>
                  <p>{description1}</p>

                  <Link to={link} onClick={handleLinkClick}>
                    <button className="btn">{buttonText}</button>
                  </Link>
                </div>
              </div>
            </header>
          </div>

          <div className="shadow one"></div>
          <div className="shadow two"></div>
        </div>

        <div className="links">
          <ul>
            <li>
              <Link to="/" style={{ '--i': '0.05s' }} onClick={handleLinkClick}>Home</Link>
            </li>
            <li>
              <Link to="/courses" style={{ '--i': '0.1s' }} onClick={handleLinkClick}>Courses</Link>
            </li>
            <li>
              <Link to="/services" style={{ '--i': '0.15s' }} onClick={handleLinkClick}>Services</Link>
            </li>
            <li>
              <Link to="/projects" style={{ '--i': '0.2s' }} onClick={handleLinkClick}>Projects</Link>
            </li>
            <li>
              <Link to="/about" style={{ '--i': '0.25s' }} onClick={handleLinkClick}>About</Link>
            </li>
            <li>
              <Link to="/contact" style={{ '--i': '0.3s' }} onClick={handleLinkClick}>Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
