import { Route, Routes } from "react-router-dom"
import MainContact from "../Pages/MainContact"
import Home from "../Components/Home"
import MainService from "../Components/MainServices/MianServices"
import About from "../Components/About/About"
import Projects from "../Components/Project/Projects"
import Otherproject from "../Components/Project/Otherproject"
import Course from "../Components/Course/Course"

const Allroutes = () => {
  return (
<Routes>
<Route path="/" element={<Home/>} />
 
    <Route path="/contact" element={<MainContact/>} />
    <Route path="/services" element={<MainService/>} />
    <Route path="/about" element={<About/>} />
    <Route path="/projects" element={<Otherproject/>} />
    <Route path="/courses" element={<Course/>} />





</Routes>
  )
}

export default Allroutes
