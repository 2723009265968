import './MainServices.css'; // Ensure you have this CSS file for styles
import userIcon from '../../assets/icon/user.png';
import cmsIcon from '../../assets/icon/cms.png';
import scalIcon from '../../assets/icon/scal.jpg';
import performIcon from '../../assets/icon/perform.png';
import seoIcon from '../../assets/icon/seo.png';
import securityIcon from '../../assets/icon/security.jpg';
import ecomersIcon from '../../assets/icon/ecomers.jpg';
import mobileIcon from '../../assets/icon/mobile.jpg';
import socialIcon from '../../assets/icon/social.jpg';
import contactformIcon from '../../assets/icon/contactform.jpg';
import anylisisIcon from '../../assets/icon/anylisis.jpg';
import brandIcon from '../../assets/icon/brand.jpg';
import cdnIcon from '../../assets/icon/cdn.jpg';
import accessIcon from '../../assets/icon/access.jpg';
import backupIcon from '../../assets/icon/backup.jpg';
import colabrationIcon from '../../assets/icon/colabration.jpg';
import ligalIcon from '../../assets/icon/ligal.jpg';
import lastIcon from '../../assets/icon/last.jpg';
import webdesineIcon from '../../assets/icon/webdesine.jpg';
import dominIcon from '../../assets/icon/domin.jpg';
import hoistingIcon from '../../assets/icon/hoisting.jpg';
import responsiveIcon from '../../assets/icon/responsive.jpg';
import userappIcon from '../../assets/icon/userapp.jpg';
import apiIcon from '../../assets/icon/api.jpg';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

const functionalities = [
  {
    use: userIcon,
    title: "User-Friendly Interface",
    description: "Intuitive navigation and responsive design.",
  },
  {
    use: cmsIcon,
    title: "Content Management System (CMS)",
    description: "Easy-to-use CMS for content management.",
  },
  {
    use: scalIcon,
    title: "Scalability",
    description: "Ability to scale the website as the business grows.",
  },
  {
    use: performIcon,
    title: "Performance Optimization",
    description: "Fast loading times and optimization of assets.",
  },
  {
    use: seoIcon,
    title: "Search Engine Optimization (SEO)",
    description: "SEO-friendly structure and metadata.",
  },
  {
    use: securityIcon,
    title: "Security",
    description: "Implementation of security measures and SSL certificates.",
  },
  {
    use: ecomersIcon,
    title: "E-Commerce Functionality",
    description: "Shopping cart system, payment gateways, and inventory management.",
  },
  {
    use: mobileIcon,
    title: "Mobile Responsiveness",
    description: "Mobile-friendly design for seamless user experience.",
  },
  {
    use: socialIcon,
    title: "Social Media Integration",
    description: "Integration with social media platforms.",
  },
  {
    use: contactformIcon,
    title: "Contact Forms and Communication",
    description: "Easy-to-use contact forms and integration with email services.",
  },
  {
    use: anylisisIcon,
    title: "Analytics and Reporting",
    description: "Integration with analytics tools and customized reports.",
  },
  {
    use: brandIcon,
    title: "Customization and Branding",
    description: "Flexible design options and customizable themes.",
  },
  {
    use: cdnIcon,
    title: "Content Delivery Network (CDN)",
    description: "Utilization of CDN for faster content delivery.",
  },
  {
    use: accessIcon,
    title: "Accessibility",
    description: "Compliance with web accessibility standards (WCAG).",
  },
  {
    use: backupIcon,
    title: "Backup and Recovery",
    description: "Regular automated backups and easy recovery options.",
  },
  {
    use: colabrationIcon,
    title: "Collaboration and Communication",
    description: "Collaboration tools and project management systems.",
  },
  {
    use: ligalIcon,
    title: "Legal Compliance",
    description: "Compliance with legal requirements, including GDPR.",
  },
  {
    use: lastIcon,
    title: "Support and Maintenance",
    description: "Ongoing support and maintenance services.",
  },
  {
    use: webdesineIcon,
    title: "Website Design",
    description: "Professional website design services tailored to your business needs.",
  },
  {
    use: dominIcon,
    title: "Domain Registration",
    description: "Secure and register your unique domain name for your online presence.",
  },
  {
    use: hoistingIcon,
    title: "Web Hosting",
    description: "Reliable web hosting services to ensure your website is accessible to users.",
  },
  {
    use: responsiveIcon,
    title: "Mobile Responsiveness",
    description: "Optimized design to ensure seamless user experience on various devices, including smartphones and tablets.",
  },
  {
    use: userappIcon,
    title: "User-Friendly App Design",
    description: "Create an intuitive and user-friendly app interface that enhances the overall user experience, making it easy for users to navigate and interact with the application.",
  },
  {
    use: apiIcon,
    title: "API Integration",
    description: "Incorporate seamless API integrations to enhance app functionality, connect with third-party services, and provide users with dynamic and up-to-date content.",
  },
];

const MainService = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  return (
    // <div className="service-container">
    //   <div className="functionality-grid">
    //     {functionalities.map((func, index) => (
    //       <div className="functionality-card" key={index}>
    //         <img className="functionality-image" src={func.use} alt={func.title} />
    //         <div className="functionality-content">
    //           <h3>{func.title}</h3>
    //           <p>{func.description}</p>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>

    <div className="gallery-container">
      <Helmet>
        <title>Raicoder's Services</title>
        <meta name="description" content=" Our Services
RaiCoder's services, in simple terms, can be considered as a package of solutions that cater for every need of our clients and students. Equipping individuals and companies with skills and tools that will enable them to stay abreast of the current digital landscape is our goal.

Web Development
We have an in-house web development team building quality, responsive websites tailored to your needs-from simple landing pages to complex web applications-we can bring your vision into reality with cutting-edge technology and best practices.

Digital Marketing
Not long ago, an excellent website was the holy grail of online success. Today, because of the intense competition in the online market, a great website is just a start. We offer digital marketing services that ensure your online presence is correctly marketed and reaches your target audiences. From SEO to social media marketing and content marketing strategies, we make sure that you see better opportunities and engagement.

Education and Training
Education is the power to change lives. With RaiCoder, you'll be skilled in web development - including all the necessary technologies like HTML & CSS for building and styling pages; JavaScript for bringing life into your websites; MERN Stack to dive deeper into MongoDB, Express.js, React, and Node.js for full stack development.
Learning MEAN Stack: Learn the MEAN stack—MongoDB, Express.js, Angular, and Node.js—for powerful web applications
Curated for both beginner and experienced developers, our curriculum allows everyone a sure path to their level. Join us to gain practical hands-on experience and the skills needed in today's tech-driven world." />
      </Helmet>
      {functionalities.map((feature, index) => (
        <figure
          key={feature.id}
          className={`snip1200 ${hoveredIndex === index ? 'hover' : ''}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <img src={feature.use} alt={feature.title} /> {/* Render the icon */}
          <figcaption>
            <p>{feature.description}</p>
            <div className="heading">
              <h2>{feature.title}</h2>
            </div>
          </figcaption>
          <a href="#"></a>
        </figure>
      ))}
    </div>
  );
};

export default MainService;
