import React from 'react';
import "./Other.css";

// Step 1: Import images directly
import projectOne from './Projectimage/one.png';
import projectThree from './Projectimage/three.png';
import projectFour from './Projectimage/four.png';
import projectFive from './Projectimage/five.png';
import projectSix from './Projectimage/six.png';
import projectSeven from './Projectimage/seven.png';
import projectEight from './Projectimage/eight.png';
import projectNine from './Projectimage/nine.png';
import projectTen from './Projectimage/ten.png';
import projectEleven from './Projectimage/eleven.png';
import projectTwelve from './Projectimage/twelve.png';
import courseImage from './Projectimage/course.jpg';
import Projects from './Projects';
import { Helmet } from 'react-helmet';

const Otherproject = () => {
  // Step 2: Define the data array using the imported images
  const galleryItems = [
    { src: projectOne, alt: "Project 1" },
    { src: projectThree, alt: "Project 3" },
    { src: projectFour, alt: "Project 4" },
    { src: projectFive, alt: "Project 5" },
    { src: projectSix, alt: "Project 6" },
    { src: projectSeven, alt: "Project 7" },
    { src: projectEight, alt: "Project 8" },
    { src: projectNine, alt: "Project 9" },
    { src: projectTen, alt: "Project 10" },
    { src: projectEleven, alt: "Project 11" },
    { src: projectTwelve, alt: "Project 12" },
    // { src: courseImage, alt: "Course Image" },
  ];

  return (
    <div>
      <Helmet>
        <title>Projects</title>
        <meta name="description" content="More than 500 Websites tailored to suit the needs of any business idea. From e-commerce interactive website platforms and interesting blogs to astonishing static websites, we help you get that vision off the ground. Be it launching an online store, relating travel adventures, or establishing a gaming hub-all is possible at RaiCoder. Commitment to quality, with a strong drive for customer satisfaction, will ensure that every project is developed with care and the latest technology and design trends. Let's help you build a powerful online presence that resonates to the audience's heart and drives success!" />
      </Helmet>
      <main>
      <h1 className="taghead">Projects (❁+500❁)</h1>
       <Projects/>

        <article className="gallery">
          {galleryItems.map((item, index) => (
            <img
              key={index} // Added key prop to each image for React list
              className='iiimg'
              src={item.src} // Accessing the correct property
              alt={item.alt} // Providing alt text for accessibility
            />
          ))}
        </article>
      </main>
    </div>
  );
};

export default Otherproject;
