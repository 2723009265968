import React from 'react';
import './Course.css';
import imagee from "../../assets/About/image.png"
import { Helmet } from 'react-helmet';


const Course = () => {
  return (
    <>
 <Helmet>
        <title>Course </title>
        <meta name="description" content="At RaiCoder, our courses are tailored down to the most minute detail in enabling the developers of tomorrow with the skills they will need to master in today's tech world. We're giving comprehensive training in web development with majors in both front-end and back-end technologies, so hands-on learning is provided through practices and engaging in HTML, CSS, JavaScript and MERN stack- MongoDB, Express.js, React, and Node.js-to give a proper, well-rounded understanding of the modern web.

Our programs are catered to all levels of development - from the very beginner till the advanced ones. With expert instructors, interactive sessions, and real-world projects, we ensure that you will receive a life-altering learning experience. Join us at RaiCoder and take the first step to succeeding in the world of tech!" />
      </Helmet>


    <div  className="flexbox1">

      <div className="course-card">
        {/* Content Section */}
        <div className="course-card__content">
          <h2 className="course-card__title">Web Designing Course</h2>
          <p className="course-card__subtitle">
            <b>Skills You Will Learn:</b> HTML, CSS, JavaScript, Bootstrap, UI/UX Design, Layout Design
          </p>
          <p className="course-card__location">
            <b>Tools Covered:</b> Git, GitHub, VS Code, Netlify, Heroku, Render, Cyclic, Railway
          </p>
          <p className="course-card__description">
            This course includes over 10 hands-on projects, including portfolio building and deployment. You'll develop responsive websites and enhance your design skills, all while mastering modern deployment tools.
          </p>
          <ul className="course-card__features">
            <li>10+ Projects (e.g., YouTube Portfolio)</li>
            <li>Master modern web development tools</li>
            <li>Learn the principles of UI/UX design</li>
            <li>Portfolio Building & Deployment using Netlify, Heroku, and more</li>
          </ul>
          <a href="#enroll" className="course-card__button">
            <i className="ri-shopping-cart-2-line"></i> Enroll Now
          </a>
        </div>

       
      </div>



<div className='scrollnone'>
      <div className="roadmap">
      <h1>Web Designing Course Roadmap</h1>

      <section className="sectionk">
        <h2>1. Introduction to Web Development</h2>
        <ul>
          <li>Overview of Web Development</li>
          <li>Understanding Frontend vs. Backend</li>
          <li>Basic Web Technologies (HTML, CSS, JavaScript)</li>
        </ul>
      </section>

      <section className="sectionk">
        <h2>2. HTML Basics</h2>
        <ul>
          <li>Structure of an HTML Document</li>
          <li>Common HTML Tags</li>
          <li>Semantic HTML</li>
          <li>Forms and Input Elements</li>
          <li>Accessibility Best Practices</li>
        </ul>
      </section>

      <section className="sectionk">
        <h2>3. CSS Fundamentals</h2>
        <ul>
          <li>Introduction to CSS</li>
          <li>Selectors, Properties, and Values</li>
          <li>Box Model (margin, padding, border)</li>
          <li>CSS Flexbox and Grid Layout</li>
          <li>Responsive Design Principles (Media Queries)</li>
          <li>CSS Preprocessors (Sass or LESS)</li>
        </ul>
      </section>

      <section className="sectionk">
        <h2>4. JavaScript Basics</h2>
        <ul>
          <li>Introduction to JavaScript</li>
          <li>Variables, Data Types, and Operators</li>
          <li>Control Structures (if statements, loops)</li>
          <li>Functions and Scope</li>
          <li>DOM Manipulation</li>
          <li>Events and Event Handling</li>
        </ul>
      </section>

      <section className="sectionk">
        <h2>5. Advanced JavaScript</h2>
        <ul>
          <li>ES6+ Features (Arrow Functions, Promises, async/await)</li>
          <li>Object-Oriented JavaScript</li>
          <li>JavaScript Libraries (e.g., jQuery)</li>
          <li>Introduction to ES Modules</li>
        </ul>
      </section>

      <section className="sectionk">
        <h2>6. UI/UX Design Principles</h2>
        <ul>
          <li>Understanding User Experience (UX)</li>
          <li>Principles of User Interface (UI) Design</li>
          <li>Wireframing and Prototyping Tools (e.g., Figma, Adobe XD)</li>
          <li>Color Theory and Typography</li>
        </ul>
      </section>

      <section className="sectionk">
        <h2>7. Frameworks and Libraries</h2>
        <ul>
          <li>Introduction to CSS Frameworks (Bootstrap)</li>
          <li>Building Responsive Layouts with Bootstrap</li>
          <li>Introduction to JavaScript Frameworks (React)</li>
          <li>Components, Props, and State in React</li>
        </ul>
      </section>

      <section className="sectionk">
        <h2>8. Version Control with Git</h2>
        <ul>
          <li>Understanding Git and GitHub</li>
          <li>Basic Git Commands (clone, commit, push, pull)</li>
          <li>Branching and Merging</li>
          <li>Collaborating on GitHub</li>
        </ul>
      </section>

      <section className="sectionk">
        <h2>9. Deployment</h2>
        <ul>
          <li>Introduction to Deployment</li>
          <li>Hosting Options (Netlify, Heroku, Render, Cyclic, Railway)</li>
          <li>Continuous Integration/Continuous Deployment (CI/CD) Basics</li>
          <li>Domain Registration and Setup</li>
        </ul>
      </section>

      <section className="sectionk">
        <h2>10. Project Development</h2>
        <ul>
          <li>Building Real-World Projects</li>
          <li>Portfolio Development</li>
          <li>Best Practices for Code Organization</li>
          <li>Testing and Debugging</li>
        </ul>
      </section>

      <section className="sectionk">
        <h2>11. Final Project</h2>
        <ul>
          <li>Conceptualizing and Planning a Final Project</li>
          <li>Implementing the Project</li>
          <li>Presenting and Reviewing the Project</li>
        </ul>
      </section>

      <section className="sectionk">
        <h2>Additional Resources</h2>
        <ul>
          <li>Recommended Reading Materials</li>
          {/* <li>Online Courses and Tutorials</li> */}
          <li>Community and Forum Recommendations</li>
        </ul>
      </section>

    </div>
    </div>
    </div>







    <div  className="flexbox1">

    <div className='scrollnone'>
<div className="roadmap">
<h1>Advanced React with Redu</h1>

<section className="sectionk">
  <h2>1. Core Concepts</h2>
  <ul>
  <li>React Basics: Components, Props, State, Lifecycle Methods</li>
        <li>Advanced React: Hooks (useState, useEffect, useContext, custom hooks)</li>
        <li>JSX and Rendering Elements</li>
        <li>Component Composition and Patterns</li>
  </ul>
</section>

<section className="sectionk">
  <h2>2. State Management with Redux</h2>
  <ul>
  <li>Understanding Redux: Actions, Reducers, Store</li>
        <li>Setting up Redux Toolkit for easier state management</li>
        <li>Using Redux with React: useDispatch and useSelector</li>
        <li>Handling Side Effects with Redux Thunk or Redux Saga</li>
  </ul>
</section>

<section className="sectionk">
  <h2>3. API Integration</h2>
  <ul>
  <li>Mocking API Calls with tools like JSON Server or Mock Service Worker (MSW)</li>
        <li>Understanding CRUD operations: Create, Read, Update, Delete</li>
        <li>Fetching data using Axios or Fetch API</li>
        <li>Handling responses and errors effectively</li>
  </ul>
</section>

<section className="sectionk">
<h2 >4. Building the Application</h2>
      <ul >
        <li>Setting up folder structure (components, redux, services, etc.)</li>
        <li>Creating a basic CRUD application (e.g., Task Manager, Blog, Portfolio)</li>
        <li>Implementing Routing with React Router</li>
        <li>Using Context API for simpler state management in smaller components</li>
      
  </ul>
</section>

<section className="sectionk">
  
<h2 >5. Testing and Deployment</h2>
      <ul >
        <li>Writing Unit Tests with Jest and React Testing Library</li>
        <li>End-to-End Testing with Cypress</li>
        <li>Deploying the application using Netlify, Vercel, or GitHub Pages</li>
      </ul>

     

      
</section>

<section className="sectionk">
<h2 >6. Advanced Topics</h2>
<ul >
  <li>Performance Optimization: Code Splitting, Lazy Loading</li>
  <li>Accessibility (a11y) considerations in React apps</li>
  <li>Using TypeScript with React and Redux</li>
  <li>Implementing Progressive Web App (PWA) features</li>
</ul>
</section>

<section className="sectionk">
<h2 >7. Continuous Learning</h2>
      <ul>
        <li>Stay updated with React ecosystem changes and updates</li>
        <li>Contribute to open-source projects</li>
        <li>Build and iterate on personal projects to showcase skills</li>
      </ul>
</section>



</div>
</div>







<div className="course-card">
        {/* Content Section */}
        <div className="course-card__content">
          <h2 className="course-card__title">Advanced Frontend Course</h2>
          <p className="course-card__subtitle">
            <b>Skills You Will Learn:</b> HTML, CSS, JavaScript, React, Redux, Chakra UI, API CRUD Operations, JSON Handling
          </p>
          <p className="course-card__location">
            <b>Tools Covered:</b> Git, GitHub, VS Code, Netlify, Heroku, Render, Cyclic, Railway
          </p>
          <p className="course-card__description">
            This advanced course covers in-depth web development techniques and best practices. You will work on real-world projects, enhancing your skills in front-end and deployment tools.
          </p>
          <ul className="course-card__features">
            <li>Building responsive user interfaces with Chakra UI</li>
            <li>State management with Redux for complex applications</li>
            <li>CRUD operations using REST APIs</li>
            <li>Advanced JSON data handling and manipulation</li>
            <li>Deploy applications using various platforms like Netlify and Heroku</li>
            <li>Best practices in version control and project management</li>
          </ul>
          <a href="#enroll" className="course-card__button">
            <i className="ri-shopping-cart-2-line"></i> Enroll Now
          </a>
        </div>

      </div>
</div>








<div  className="flexbox1">



<div className='roadma'>
    <img width={"100%"} src={imagee} alt="" />
</div>


<div className="course-card">
      {/* Content Section */}
      <div className="course-card__content">
        <h2 className="course-card__title">MERN Stack Web Development Course</h2>
        <p className="course-card__subtitle">
          <b>Skills You Will Learn:</b> MongoDB, Express.js, React.js, Node.js, REST API, JWT Authentication
        </p>
        <p className="course-card__location">
          <b>Tools Covered:</b> Git, GitHub, Postman, VS Code, MongoDB Atlas, Heroku, Netlify, Railway
        </p>
        <p className="course-card__description">
          This course dives deep into full-stack web development using the MERN stack. You'll build dynamic, data-driven web applications from scratch and deploy them using modern cloud platforms.
        </p>
        <ul className="course-card__features">
          <li>Build full-stack applications using MongoDB, Express, React, and Node.js</li>
          <li>Implement authentication using JWT and secure REST APIs</li>
          <li>Master the React framework for building responsive UIs</li>
          <li>Develop backends with Node.js and Express for data handling</li>
          <li>Deploy applications on platforms like Heroku and Netlify</li>
          <li>Version control and best practices with Git and GitHub</li>
        </ul>
        <a href="#enroll" className="course-card__button">
          <i className="ri-shopping-cart-2-line"></i> Enroll Now
        </a>
      </div>

      
    </div>
</div>
    </>
  );
};

export default Course;
