import "./Project.css";
import imga from "../../assets/Projects/pro1.png"
import imga1 from "../../assets/Projects/2.png"
import imga2 from "../../assets/Projects/3.png"
import imga3 from "../../assets/Projects/4.png"
import imga4 from "../../assets/Projects/5.png"
import imga5 from "../../assets/Projects/6.png"
import imga6 from "../../assets/Projects/7.png"
import imga7 from "../../assets/Projects/8.png"
import imga8 from "../../assets/Projects/9.png"
import imga9 from "../../assets/Projects/10.png"
import imga10 from "../../assets/Projects/11.png"
import { Helmet } from "react-helmet";





const Projects = () => {
  // Define the images for different screen sizes
  const imagesBase = [
   imga,
   imga1,
   imga2,
   imga3,
   imga4,
   imga5,
   imga6,
   imga7,
   imga8,
   imga9,
   imga10,





    // Add more base images here
  ];

  const imagesSmallScreen = [
    imga,
    imga1,
    imga2,
    imga3,
    imga4,
    imga5,
    imga6,
   
 
 
    // Add more images for small screens here
  ];

  // Check window width to decide which set of images to use
  const isSmallScreen = window.innerWidth <= 768;

  const imagesToDisplay = isSmallScreen ? imagesSmallScreen : imagesBase;

  return (
    <div>
      

      <div className="wrapper">
        <div className="items">
          {imagesToDisplay.map((image, index) => (
            <div
              key={index}
              className="item"
              tabIndex={0}
              style={{
                backgroundImage: `url(${image})`
              }}
            />
          ))}
        </div>
      </div>

   
    </div>
  );
};

export default Projects;
