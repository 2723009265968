import { Helmet } from "react-helmet"
import Contact from "../Components/Contact/Contact"


const MainContact = () => {
  return (
    <div>
       <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="We are passionate about empowering individuals with the skills they need to thrive in the tech industry at RaiCoder. We are here to encourage and support you no matter what level of journey you are at.
If you would like to know more about our programs, curriculums or the sign-up process, or if you just want to learn more about how we can help you code the way that you need to be coding - please do not hesitate to get in touch. Our team is happy to answer your questions and provide further information whenever possible." />
      </Helmet>
    
      <Contact/>
  
    </div>
  )
}

export default MainContact
