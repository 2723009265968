import logo from './logo.svg';
import './App.css';
import Allroutes from './AllRoutes/Allroutes';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import { ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }
  return (
    <div className="App">
       <ScrollToTop />
      <Navbar/>
      <Allroutes/>
      <Footer/>
      <ToastContainer/>
    </div>
  );
}

export default App;
