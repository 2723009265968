import "./Service.css";
import img1 from "../../assets/Course/React-icon.svg.png";
import img2 from "../../assets/Course/web.png";
import img3 from "../../assets/Course/MVM.png";
import img4 from "../../assets/Course/DSA.png";




const Service = () => {
  return (
    <div className="projectmain">
      <main className="main">
        <section className="card-area">
          {/* Card: City */}
          <section className="card-section">
            <div className="card">
              <div className="flip-card">
                <div className="flip-card__container">
                  <div className="card-front">
                    <div className="card-front__tp card-front__tp--city">
                      <img height={"100px"} src={img2} alt="HTML5 Icon" />
                      <h2 className="card-front__heading">Web Development</h2>
                      {/* <p className="card-front__text-price">From ₹4999</p> */}
                    </div>
                    <div className="card-front__bt">
                      <p className="card-front__text-view card-front__text-view--city">
                        View me
                      </p>
                    </div>
                  </div>
                  <div className="card-back">
                   
                    <div className="inside-page__container">
                  <h3 className="inside-page__heading inside-page__heading--city">
                    Web Devlopment
                  </h3>
                  <p className="inside-page__text">
                  Join the Raicoder Web Development Course and transform your ideas into stunning websites!
                  </p>
                  <a href="#" className="inside-page__btn inside-page__btn--city">
                    View deals
                  </a>
                </div>
                  </div>
                </div>
              </div>
              <div className="inside-page">
              <video className="video__container" autoPlay muted loop>
                      <source
                        className="video__media"
                        src="https://media.istockphoto.com/id/1193269492/video/the-programmer-writes-the-code-for-the-development-of-the-website-against-the-background-of-a.mp4?s=mp4-640x640-is&k=20&c=dTfrfZcUFLFFE8V9RAFbWi-4unpnXf787pCDSfmAFs4="

                        type="video/mp4"
                      />
                    </video>
              </div>
            </div>
          </section>

          {/* Card: Ski */}
          <section className="card-section">
            <div className="card">
              <div className="flip-card">
                <div className="flip-card__container">
                  <div className="card-front">
                    <div className="card-front__tp card-front__tp--ski">
                      <img height={"100px"} src={img1} alt="Ski Icon" />
                      <h2 className="card-front__heading">React JS</h2>
                      {/* <p className="card-front__text-price">From ₹4999</p> */}
                    </div>
                    <div className="card-front__bt">
                      <p className="card-front__text-view card-front__text-view--ski">
                        View me
                      </p>
                    </div>
                  </div>
                  <div className="card-back">
                
                <div className="inside-page__container">
                  <h3 className="inside-page__heading inside-page__heading--ski">
                  Learn React.js
                  </h3>
                  <p className="inside-page__text">
                  Join Raicoder to master React JS and transform your ideas into stunning web applications effortlessly!
                  </p>
                  <a href="#" className="inside-page__btn inside-page__btn--ski">
                    View deals
                  </a>
                </div>
              
                  </div>
                </div>
              </div>
             
              <div className="inside-page">
              <video className="video__container" autoPlay muted loop>
                      <source
                        className="video__media"
                        src="https://media.istockphoto.com/id/1193269492/video/the-programmer-writes-the-code-for-the-development-of-the-website-against-the-background-of-a.mp4?s=mp4-640x640-is&k=20&c=dTfrfZcUFLFFE8V9RAFbWi-4unpnXf787pCDSfmAFs4="
                        type="video/mp4"
                      />
                    </video>
                    </div>
            </div>
          </section>

          {/* Card: Beach */}
          <section className="card-section">
            <div className="card">
              <div className="flip-card">
                <div className="flip-card__container">
                  <div className="card-front">
                    <div className="card-front__tp card-front__tp--camping">
                      <img width={"100%"} src={img3} alt="Ski Icon" />
                      {/* <h2 className="card-front__heading">React JS</h2> */}
                      {/* <p className="card-front__text-price">From ₹4999</p> */}
                    </div>
                    <div className="card-front__bt">
                      <p className="card-front__text-view card-front__text-view--ski">
                        View me
                      </p>
                    </div>
                  </div>
                  <div className="card-back">
                  <div className="inside-page__container">
                  {/* <h3 className="inside-page__heading inside-page__heading--ski">
                    FSD
                  </h3> */}
                  <p className="inside-page__text">
                  Join Raicoder's MERN course to master full-stack development and create powerful web applications that make a difference!
                  </p>
                  <a href="#" className="inside-page__btn inside-page__btn--ski">
                    View deals
                  </a>
                </div>


                  </div>
                </div>
              </div>
              <div className="inside-page">
              <video className="video__container" autoPlay muted loop>
                      <source
                        className="video__media"
                        src="https://media.istockphoto.com/id/1193269492/video/the-programmer-writes-the-code-for-the-development-of-the-website-against-the-background-of-a.mp4?s=mp4-640x640-is&k=20&c=dTfrfZcUFLFFE8V9RAFbWi-4unpnXf787pCDSfmAFs4="
                        type="video/mp4"
                      />
                    </video>
               
              </div>
            </div>
          </section>


          <section className="card-section">
            <div className="card">
              <div className="flip-card">
                <div className="flip-card__container">
                  <div className="card-front">
                    <div className="card-front__tp card-front__tp--beach">
                      <img width={"100%"} src={img4} alt="Ski Icon" />
                      <h2 className="card-front__heading" style={{marginTop:"-10px"}}>400+ Quations</h2>
                      {/* <p className="card-front__text-price">From ₹4999</p> */}
                    </div>
                    <div className="card-front__bt">
                      <p className="card-front__text-view card-front__text-view--ski">
                        View me
                      </p>
                    </div>
                  </div>
                  <div className="card-back">
                    
                  <div className="inside-page__container">
                  {/* <h3 className="inside-page__heading inside-page__heading--ski">
                    For snow lovers
                  </h3> */}
                  <p className="inside-page__text">
                  Elevate your coding skills with Raicoder's DSA in JavaScript course, featuring 400+ challenging questions designed to sharpen your problem-solving abilities
                  </p>
                  <a href="#" className="inside-page__btn inside-page__btn--ski">
                    View deals
                  </a>
                </div>

                  </div>
                </div>
              </div>
              <div className="inside-page">

              <video className="video__container" autoPlay muted loop>
                      <source
                        className="video__media"
                        src="https://media.istockphoto.com/id/1193269492/video/the-programmer-writes-the-code-for-the-development-of-the-website-against-the-background-of-a.mp4?s=mp4-640x640-is&k=20&c=dTfrfZcUFLFFE8V9RAFbWi-4unpnXf787pCDSfmAFs4="

                        type="video/mp4"
                      />
                    </video>
               
              </div>
            </div>
          </section>
         

          {/* Additional cards can be added here */}
        </section>
      </main>
    </div>
  );
};

export default Service;
